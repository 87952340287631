import { UploadFile } from 'components/Upload/types';
import { convertObjectFormData } from 'helpers/object';

export const convertDataFromServer = <T extends object>(obj: T) => {
  const newData: any = {};

  Object.keys(obj).forEach(key => {
    const keyT = key as keyof T;
    const filter = obj[keyT];

    if (Array.isArray(filter)) {
      if (filter?.length) newData[keyT] = filter;
    } else {
      if (filter !== undefined) newData[keyT] = filter;
    }
  });

  return newData;
};

export const convertFilledData = <T extends object>(obj: T) => {
  const convertData: any = {};
  Object.keys(obj).forEach(key => {
    const keyT = key as keyof T;
    const field = obj[keyT];

    if (!field || (Array.isArray(field) && !field.length)) return;
    convertData[keyT] = field;
  });

  return convertData;
};

export const convertFormData = <T extends object>(
  data: T,
  files?: UploadFile[][],
  dataAsSingleJson = false, // При включенном флаге упаковывает все данные в одну переменную, а не каждую отдельно
  doNotAddIndex?: string[],
) => {
  const formData = new FormData();

  if (dataAsSingleJson) {
    formData.append('data', JSON.stringify(data));
  } else {
    Object.keys(data).forEach(key => {
      const field = data[key as keyof T];
      if (field) formData.append(key, convertObjectFormData(field));
    });
  }

  files?.forEach(filesField => {
    filesField?.forEach((file: UploadFile, i: number) => {
      let name = `${file.fileName}`;
      if (!doNotAddIndex?.every(name => file.fileName?.includes(name))) {
        name += i + 1;
      }

      return formData.append(name, convertObjectFormData(file), file.name);
    });
  });

  return formData;
};

const versionRegexp = /(\d+\.)(\d+\.)(\d+\.?)(\d+)?/;

export const isValidVersion = (version: string | undefined) =>
  version && versionRegexp.test(version);
