import React, { useCallback, useEffect, useState } from 'react';
import { ONE_MINUTE, ONE_SECOND } from '@webcommon/constants';
import { Dialog } from 'components/Dialog';
import { isValidVersion } from 'helpers/request';
import { getLocalStorage, setLocalStorage } from 'helpers';
import { getStandFromEnv } from 'utils/stand';
import { Dayjs, getDayjs } from 'helpers/time';
import useAnalyticsLogger from 'controllers/loggers/AnalitycsLoggerController/hooks';
import { EAnalyticsLoggerEventNames } from 'services/logger/AnalyticsLoggerService/types';
import { caseFactory } from 'services/logger/AnalyticsLoggerService/utils';
import { useCheckVersion } from './useCheckVersion';

const VersionChecker: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const stand = getStandFromEnv();
  const storageVersionKey = `stender_${stand}_version`;
  const { appVersion } = useCheckVersion();

  const logger = useAnalyticsLogger();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [reloadTime, setReloadTime] = useState<Dayjs | null>(null);
  const [lastVersion, setLastVersion] = useState<string>('');

  const handleOk = useCallback(
    (version: string) => {
      const savedVersion = getLocalStorage(storageVersionKey);

      setLocalStorage(storageVersionKey, JSON.stringify(version));

      logger.log(
        caseFactory({
          name: EAnalyticsLoggerEventNames.ModalClick,
        })
          .itemId(`${savedVersion},${version}`)
          .context('Обновить'),
      );

      window.location.reload();
    },
    [storageVersionKey, logger],
  );

  const handleCancel = useCallback(
    (version: string) => {
      const savedVersion = getLocalStorage(storageVersionKey);

      setLastVersion(version);
      setReloadTime(getDayjs().add(ONE_MINUTE * 3));

      logger.log(
        caseFactory({
          name: EAnalyticsLoggerEventNames.ModalClick,
        })
          .itemId(`${savedVersion},${version}`)
          .context('Обновить через 3 минуты'),
      );
    },
    [logger, storageVersionKey],
  );

  useEffect(() => {
    setLocalStorage(storageVersionKey, JSON.stringify(appVersion));
  }, [appVersion, storageVersionKey]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (reloadTime) {
      interval = setInterval(() => {
        if (getDayjs().isAfter(reloadTime)) {
          setLocalStorage(storageVersionKey, JSON.stringify(lastVersion));
          window.location.reload();
        }
      }, ONE_SECOND);
    }

    return () => (interval ? clearInterval(interval) : undefined);
  }, [lastVersion, reloadTime, storageVersionKey]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDialogVisible) {
        const savedVersion = getLocalStorage<string>(storageVersionKey);

        if (
          isValidVersion(appVersion) &&
          isValidVersion(savedVersion) &&
          appVersion !== savedVersion
        ) {
          setIsDialogVisible(true);

          logger.log(
            caseFactory({
              name: EAnalyticsLoggerEventNames.ModalShow,
            }).itemId(`${savedVersion},${appVersion}`),
          );

          Dialog.info({
            title: 'Доступно обновление системы',
            content:
              'Для стабильной работы продукта выполните обновление. Несохраненные данные будут потеряны.',
            closable: false,
            okType: 'primary',
            okText: 'Обновить',
            cancelText: 'Обновить через 3 минуты',
            onOk: () => handleOk(appVersion),
            onCancel: () => handleCancel(appVersion),
          });
        }
      }
    }, ONE_MINUTE);

    return () => clearInterval(interval);
  }, [handleCancel, handleOk, isDialogVisible, storageVersionKey, logger, appVersion]);

  return <>{children}</>;
};

export default VersionChecker;
