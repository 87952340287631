import styled from 'styled-components';
import { HeaderBackgroundLine } from '../Icon/Custom';

const HEADER_HEIGHT_MOBILE = 48;

export const Wrapper = styled.div`
  width: 100vw;
  height: ${HEADER_HEIGHT_MOBILE}px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const Background = styled.img.attrs({ src: HeaderBackgroundLine })`
  width: 100vw;
  height: ${HEADER_HEIGHT_MOBILE}px;
`;
