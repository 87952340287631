import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { profileApi } from 'api';
import { queryKey } from 'api/types';
import HeaderBackground from 'components/HeaderBackground';

const RequestsPreloader: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  useQuery({
    queryKey: [queryKey.profile],
    queryFn: () => profileApi.get(),
    // Optional optimization to avoid rerenders when this query changes:
    notifyOnChangeProps: [],
  });

  return (
    <>
      <HeaderBackground />
      {children}
    </>
  );
};

export default RequestsPreloader;
