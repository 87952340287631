import React from 'react';
import { useDispatchWaffles, useWaffle } from 'hooks/useWaffle';
import { FullScreenSpinner } from 'components/Spinner';

/*
  Получаем фичатогглы в первую очередь, т.к. от них зависит выключение анонимов в глобале
 */
const FeatureToggles: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { isLoading } = useWaffle();
  const { isDispatched } = useDispatchWaffles();

  if (isLoading || !isDispatched) return <FullScreenSpinner />;

  return <>{children}</>;
};

export default FeatureToggles;
