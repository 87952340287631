export const MOBILE = [0, 767];

export enum EPageScreenModes {
  DesktopBig = 1728,
  DesktopMedium = 1024,
  DesktopSmall = 769,

  Mobile = 768,
  MobileSmall = 366,
}
