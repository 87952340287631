import { TIME_MINUTES_INVERVAL } from '@webcommon/constants';
import dayjs, { ConfigType, Dayjs as IDayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ru from 'dayjs/locale/ru';

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.locale(ru);

type DateType = string | Dayjs | null;
export const getDayjs = dayjs;
export type Dayjs = IDayjs;
export const ruLocale = ru;

export const DATE_FORMAT = 'DD.MM.YYYY';

export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `DD.MM.YYYY ${TIME_FORMAT}`;
export const DATE_AT_TIME_FORMAT = `DD.MM.YYYY в ${TIME_FORMAT}`;
const DATE_TIME_FORMAT_SHORT = `DD.MM.YY ${TIME_FORMAT}`;
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
const ANT_FORMAT = 'YYYY/MM/DD';

const normalizedDate = (date: Dayjs) => date.set('seconds', 0).set('milliseconds', 0);

export const getFormatDate = (date?: string, format?: string) => {
  return date ? getDayjs(date, format).format(DATE_FORMAT) : '';
};

export const parseToPickerFormat = (date: string) =>
  normalizedDate(getDayjs(date, ANT_FORMAT));

export const getFormatDateTime = (
  date?: string | Dayjs,
  short?: boolean,
  format: string = DATE_TIME_FORMAT,
) => {
  return date ? getDayjs(date).format(short ? DATE_TIME_FORMAT_SHORT : format) : '';
};

export const getFromDateTimeString = (date: string) => {
  return getDayjs(date, DATE_TIME_FORMAT);
};

export const getDiffDates = (firstDate: string, secondDate: string) => {
  return +getDayjs(firstDate) - +getDayjs(secondDate);
};

export const parseToServerDate = (date?: string | Dayjs) => {
  return date ? getDayjs(date, DATE_FORMAT).format(SERVER_DATE_FORMAT) : null;
};

export const parseToServerTimeDate = (date?: ConfigType) => {
  return date ? `${getDayjs.utc(date).format()}` : '';
};

export const toDayjsDate = (date?: DateType) => {
  if (!date) return null;

  if (getDayjs.isDayjs(date)) return normalizedDate(date);

  return normalizedDate(getDayjs(date));
};

export const toDayjsTime = (date?: string) => {
  return date ? getDayjs(date, TIME_FORMAT) : undefined;
};

export const disabledDate = (
  date: Dayjs,
  minDate?: Dayjs | string | null,
  maxDate?: Dayjs | string | null,
) => {
  return Boolean(
    !date || (minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate)),
  );
};

type TDate = Dayjs | null | undefined;
type TReturn = {
  disabledHours?: (() => number[]) | undefined;
  disabledMinutes?: ((hour: number) => number[]) | undefined;
  disabledSeconds?: ((hour: number, minute: number) => number[]) | undefined;
};

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }

  return result;
};

export const timeRange =
  ({
    from,
    to,
    interval = TIME_MINUTES_INVERVAL,
  }: {
    from?: TDate | string;
    to?: TDate | string;
    interval?: number;
  }) =>
  (current?: TDate): TReturn => {
    const toDayjs = to ? getDayjs(to) : undefined;
    const fromDayjs = from ? getDayjs(from) : undefined;

    return {
      disabledHours: () => {
        if (current?.day() === undefined) return range(0, 24);

        const disabledHours: ReturnType<typeof range> = [];

        if (fromDayjs && current.isSame(fromDayjs, 'days')) {
          // Если все минуты заблочены, то блокируем этот час
          let additional = 0;
          if (60 - interval > fromDayjs.minute()) {
            additional = -1;
          }
          disabledHours.push(...range(0, fromDayjs.hour() + additional));
        }
        if (toDayjs && current.isSame(toDayjs, 'days')) {
          let additional = 0;
          if (toDayjs.minute() > interval) {
            additional = 1;
          }
          disabledHours.push(...range(toDayjs.hour() + additional, 24));
        }

        return disabledHours;
      },
      disabledMinutes: (hour: number) => {
        if (current?.day() === undefined || hour < -1) return range(0, 60);

        const disabledMinutes: ReturnType<typeof range> = [];

        if (fromDayjs && current.isSame(fromDayjs, 'hours')) {
          disabledMinutes.push(...range(0, fromDayjs.minute()));
        }
        if (toDayjs && current.isSame(toDayjs, 'hours')) {
          disabledMinutes.push(...range(toDayjs.minute(), 60));
        }

        return disabledMinutes;
      },
      disabledSeconds: () => [] as number[],
    };
  };
