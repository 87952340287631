import React, { CSSProperties } from 'react';
import { Background, Wrapper } from './styled';

interface IHeaderBackgroundProps {
  style?: CSSProperties;
}
const HeaderBackground: React.FC<IHeaderBackgroundProps> = props => (
  <Wrapper {...props}>
    <Background />
  </Wrapper>
);

export default HeaderBackground;
