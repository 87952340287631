import { TAppVersion } from 'api/types';

export type TAnalyticsYandexConfig = {
  counter: number;
};
export type TAnalyticsInitializationConfig = {
  loggers: { ya?: TAnalyticsYandexConfig };
  stand: TAppVersion;
};

export enum EAnalyticsLoggerParams {
  Event = 'event',
  UserId = 'user_id',
  ExternalUser = 'external_user',
  Action = 'action',
  Context = 'context',
  ItemType = 'item_type',
  ItemId = 'item_id',
  Step = 'step',
  Result = 'result',
  ErrorText = 'error_text',

  Dictionary = 'dict',
  ExtraParams = 'extra_params',
}

export enum EAnalyticsLoggerEvents {
  LeftSidebar = 'left_sidebar',
  EditCompany = 'edit_company',
  AccreditationCompany = 'accreditation_company',
  Navigation = 'navigation',
  CreateTender = 'create_tender',
  ActionMenu = 'action_menu',
  ArrangingTender = 'arranging_tender',
  ParticipateTender = 'participate_tender',
  SignContract = 'sign_contract',
  Search = 'search',
  ModalWindow = 'modal_window',
}

export enum EAnalyticsLoggerItemType {
  Tender = 'tender',
  SideMenu = 'side_menu',
  Company = 'company',
  Contract = 'contract',
  VersionModal = 'version_modal',
}

export enum EAnalyticsLoggerActions {
  ClickButton = 'click_button',
  SendForm = 'send_form',
  GoToPage = 'goto_page',
  LotDecision = 'decision',
  FilterResult = 'filter_result',
  SearchResult = 'search_result',
  ShowElement = 'show_element',
}

export enum EAnalyticsLoggerSteps {
  Empty = '',
  Enter = 'enter',
  Popup = 'popup',
  Finish = 'finish',
  ProposalAnalysis = 'proposal_analysis',
  AllowTender = 'allow_tender',
  Rebidding = 're_trade',
  OpenRebiddingDrawer = 're_trade_enter',
  SetupRebidding = 're_trade_edit',
  StartRebidding = 're_trade_start',
}

export enum EAnalyticsResult {
  Draft = 'draft',
  Success = 'success',
  Fail = 'fail',
}

export enum EAnalyticsLoggerExtraParams {
  Filters = 'filters',
  ResultCnt = 'result_cnt',
  SearchQuery = 'search_query',
}

type TAnalyticsLoggerEvents = EAnalyticsLoggerEvents;
type TAnalyticsLoggerUserId = number;
export type TAnalyticsLoggerExternalUser = 0 | 1;
type TAnalyticsLoggerAction = EAnalyticsLoggerActions;
export type TAnalyticsLoggerContext = string | undefined;
export type TAnalyticsLoggerItemType = EAnalyticsLoggerItemType | undefined;
/**
 * Если это массив, то передается как 'id1,id2,id3'
 */
export type TAnalyticsLoggerItemId = string | undefined | number;
export type TAnalyticsLoggerStep = EAnalyticsLoggerSteps | undefined;
export type TAnalyticsLoggerResult = EAnalyticsResult | undefined;
export type TAnalyticsLoggerErrorText = string | undefined;

export type TAnalyticsLoggerBaseDict = Record<any, any>;
type TAnalyticsLoggerDictionary<T extends TAnalyticsLoggerBaseDict> = T;
type TAnalyticsLoggerBaseExtraParams = Record<any, any>;
type TAnalyticsLoggerExtraParams<T extends TAnalyticsLoggerBaseExtraParams> = T;

export type TAnalyticsArg<
  TDict extends TAnalyticsLoggerBaseDict = TAnalyticsLoggerBaseDict,
  TExtraParams extends TAnalyticsLoggerBaseExtraParams = TAnalyticsLoggerBaseExtraParams,
> = {
  [EAnalyticsLoggerParams.Event]: TAnalyticsLoggerEvents;
  [EAnalyticsLoggerParams.UserId]?: TAnalyticsLoggerUserId;
  [EAnalyticsLoggerParams.ExternalUser]: TAnalyticsLoggerExternalUser;
  [EAnalyticsLoggerParams.Action]?: TAnalyticsLoggerAction;
  [EAnalyticsLoggerParams.Context]?: TAnalyticsLoggerContext;
  [EAnalyticsLoggerParams.ItemType]?: TAnalyticsLoggerItemType;
  [EAnalyticsLoggerParams.ItemId]?: TAnalyticsLoggerItemId;
  [EAnalyticsLoggerParams.Step]?: TAnalyticsLoggerStep;
  [EAnalyticsLoggerParams.Result]?: TAnalyticsLoggerResult;
  [EAnalyticsLoggerParams.ErrorText]?: TAnalyticsLoggerErrorText;
  [EAnalyticsLoggerParams.Dictionary]?: TAnalyticsLoggerDictionary<TDict>;
  [EAnalyticsLoggerParams.ExtraParams]?: TAnalyticsLoggerExtraParams<TExtraParams>;
};

export type TAnalyticsLoggerCase<T extends TAnalyticsLoggerBaseDict> = TAnalyticsArg<T>;

export enum EAnalyticsLoggerEventNames {
  /**
   * Клик по разделу в левом меню
   */
  LeftPanelClick = 'LeftPanelClick',
  /**
   * Клик по табу в тендере
   */
  TenderTabsNavigation = 'TenderTabsNavigation',
  /**
   * Клик по табу в аккредитации
   */
  AccreditationTabsNavigation = 'AccreditationTabsNavigation',
  /**
   * Переход в тендер из списка тендеров
   */
  TenderCardNavigation = 'TenderCardNavigation',
  /**
   * Переход в компанию из списка компаний
   */
  AccreditationCardNavigation = 'AccreditationCardNavigation',

  ParticipateTender = 'ParticipateTender',
  /**
   * Изменение фильтров на странице списка тендеров
   */
  Filters = 'Filters',
  /**
   * Результат работы фильтров на странице списка тендеров
   */
  FiltersResult = 'FiltersResult',
  /**
   * Создание тендера
   */
  CreateTender = 'CreateTender',
  /**
   * Сохранить или удалить тендер
   */
  SaveOrRemoveTender = 'SaveOrRemoveTender',
  /**
   * Клик по кнопке Действия в тендере
   */
  ActionMenu = 'ActionMenu',
  /**
   * Показ модалки обновления
   */
  ModalShow = 'ModalShow',
  /**
   * Клик по кнопке модалки обновления
   */
  ModalClick = 'ModalClick',
}

export enum EAnalyticsLoggerNamespaces {
  ya = 'ya',
}

export interface IAnalyticsLoggerCaseSettings {
  name: EAnalyticsLoggerEventNames;
}

export interface IAnalyticsLoggerCaseRegistrator {
  register<T extends TAnalyticsLoggerBaseDict>(arg: IAnalyticsLoggerCase<T>): void;
}

export interface IAnalyticsLoggerCase<T extends TAnalyticsLoggerBaseDict> {
  getData(): TAnalyticsArg<T>;
  getName(): EAnalyticsLoggerEventNames;
  event(value: TAnalyticsLoggerEvents): IAnalyticsLoggerCase<T>;
  userId(value: TAnalyticsLoggerUserId): IAnalyticsLoggerCase<T>;
  externalUser(value: TAnalyticsLoggerExternalUser): IAnalyticsLoggerCase<T>;
  action(value: TAnalyticsLoggerAction): IAnalyticsLoggerCase<T>;
  context(value: TAnalyticsLoggerContext): IAnalyticsLoggerCase<T>;
  itemType(value: TAnalyticsLoggerItemType): IAnalyticsLoggerCase<T>;
  itemId(value: TAnalyticsLoggerItemId): IAnalyticsLoggerCase<T>;
  step(value: TAnalyticsLoggerStep): IAnalyticsLoggerCase<T>;
  result(value: TAnalyticsLoggerResult): IAnalyticsLoggerCase<T>;
  errorText(value: TAnalyticsLoggerErrorText): IAnalyticsLoggerCase<T>;
  dictionary(value: TAnalyticsLoggerDictionary<T>): IAnalyticsLoggerCase<T>;
  extraParams(value: TAnalyticsLoggerExtraParams<T>): IAnalyticsLoggerCase<T>;
}

export interface IAnalyticsLoggerService {
  log<T extends TAnalyticsLoggerBaseDict>(arg: IAnalyticsLoggerCase<T>): void;
  logPageView(): void;
  init(config: TAnalyticsInitializationConfig): Promise<void>;
}

export interface IAnalyticsLoggerRegistrator {
  register<TDict extends TAnalyticsLoggerBaseDict = TAnalyticsLoggerBaseDict>(
    name: EAnalyticsLoggerEventNames,
    options: TAnalyticsArg<TDict>,
    namespaces?: EAnalyticsLoggerNamespaces[],
  ): void;
}
