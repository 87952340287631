import { TAppVersion } from '../api/types';

const getFavicon = (): HTMLLinkElement | null => document.querySelector('#favicon');

export const replaceFavicon = (stand: TAppVersion) => {
  const favicon = getFavicon();

  if (!favicon) return;

  switch (stand) {
    case TAppVersion.DEV:
      favicon.href = '/favicon_dev.svg';

      return;
    case TAppVersion.STAGE:
      favicon.href = '/favicon_stage.svg';

      return;
    default:
      favicon.href = '/favicon.svg';
  }
};
