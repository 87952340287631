import { createGlobalStyle, css } from 'styled-components';
import { scrollbar } from '@10d/tend-ui/styling/mixins';
import { colors } from 'constants/colors';

const antStyle = css`
  // FORM
  .ant-form,
  .ant-form-item {
    line-height: 1.55;
  }

  // INPUT + DATEPICKER
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button,
  .ant-picker-now {
    display: none !important;
  }

  .ant-picker-time-panel-column:after {
    height: 0 !important;
  }

  .ant-picker-datetime-panel + .ant-picker-footer {
    display: none;
  }

  .ant-input[disabled],
  .ant-picker-input > input[disabled],
  .ant-input::placeholder,
  .ant-picker-input > input::placeholder,
  .ant-select-selection-placeholder,
  .ant-select-selection-item-content,
  .ant-empty-description,
  [data-testid='tend-ui-button'][disabled],
  .ant-checkbox-disabled + span {
    color: ${colors.grayBlue7};
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Museo Sans Cyrl", sans-serif;
    font-weight: 500; // на 400 не менять, Егор не одобряет
    font-style: normal;
  }

  ${antStyle}

  ${scrollbar}
`;
