import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import Fallback from 'components/Fallback';
import { useProfileStorage } from 'hooks/profile';
import { ESentryTag } from 'utils/sentry';

const withErrorBoundary =
  (Component: React.FC<React.PropsWithChildren<unknown>>) => () => {
    const { profile, isAnonymous } = useProfileStorage();

    return (
      <ErrorBoundary
        fallback={({ error }) => {
          const hasChunkLoadError = /ChunkLoadError/.test((error as Error)?.name);

          if (hasChunkLoadError) {
            window.location.reload();
          }

          return <Fallback error={error as Error} />;
        }}
        beforeCapture={scope => {
          !isAnonymous &&
            scope.setUser({ username: profile.username, email: profile.email });
          scope.setTag(ESentryTag.Username, isAnonymous ? 'Anonymous' : profile.username);
          scope.setTag(ESentryTag.WhereWasCaught, 'ErrorBoundary component');
        }}
      >
        <Component />
      </ErrorBoundary>
    );
  };

export default withErrorBoundary;
