import React, { Suspense, lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { FullScreenSpinner } from 'components/Spinner';
import {
  ROUTE_ACCREDITATION,
  ROUTE_COMMITTEES,
  ROUTE_COMPANY,
  ROUTE_CONTRACT,
  ROUTE_CONTRACTOR,
  ROUTE_DOCUMENTATION,
  ROUTE_DOWNLOAD,
  ROUTE_FORBIDDEN,
  ROUTE_REVIEWS,
  ROUTE_SUPPORT,
  ROUTE_TENDERS,
} from '../constants/routes';
import { SentryRoutes } from '../components/SentryRoute';
import { useLoggersPagesWatcher } from '../hooks/useLoggers';
import RedirectByRoles from '../roles/Redirect';

const TendersList = lazy(() => import('./TendersList'));
const TenderView = lazy(() => import('./TenderView'));
const TenderEdit = lazy(() => import('./TenderEdit'));
const TenderWinners = lazy(() => import('./TenderWinners'));

const TenderLotProposalsAnalysis = lazy(() => import('./TenderLotProposalsAnalysis'));

const CompanyCreate = lazy(() => import('./CompanyCreate'));
const CompanyView = lazy(() => import('./CompanyView'));
const CompanyEdit = lazy(() => import('./CompanyEdit'));

const ReviewsList = lazy(() => import('./ReviewsList'));
const ReviewView = lazy(() => import('./ReviewView'));

const ContractorsList = lazy(() => import('./ContractorsList'));
const ContractorView = lazy(() => import('./ContractorView'));
const ContractorVersions = lazy(() => import('./ContractorVersions'));

const СommitteeList = lazy(() => import('./Committees/List'));
const Committee = lazy(() => import('./Committees/Committee'));

const AccreditationsList = lazy(() => import('./AccreditationsList'));
const AccreditationView = lazy(() => import('./AccreditationView'));
const AccreditationVersions = lazy(() => import('./AccreditationVersions'));

const ContractsList = lazy(() => import('./ContractsList'));
const ContractView = lazy(() => import('./ContractView'));

const DocumentationRoute = lazy(() => import('./Documentation'));
const SupportRoute = lazy(() => import('./Support'));
const ForbiddenRoute = lazy(() => import('./Forbidden'));
const DownloadRoute = lazy(() => import('./Download/'));

const AppRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
  useLoggersPagesWatcher();

  return (
    <Suspense fallback={<FullScreenSpinner />}>
      <SentryRoutes>
        <Route path={ROUTE_CONTRACTOR}>
          <Route index element={<ContractorsList />} />
          <Route path=':companyId'>
            <Route index element={<Navigate to='commonInfo' replace />} />
            <Route path=':tab' element={<ContractorView />}>
              <Route path=':segment' element={<ContractorView />} />
            </Route>
            <Route path='versions' element={<ContractorVersions />} />
          </Route>
        </Route>

        <Route path={ROUTE_ACCREDITATION}>
          <Route index element={<AccreditationsList />} />
          <Route path=':companyId'>
            <Route index element={<Navigate to='commonInfo' replace />} />
            <Route path=':tab' element={<AccreditationView />}>
              <Route path=':segment' element={<AccreditationView />} />
            </Route>
            <Route path='versions' element={<AccreditationVersions />} />
          </Route>
        </Route>

        <Route path={ROUTE_COMMITTEES}>
          <Route index element={<СommitteeList />} />
          <Route path=':committeeId/*' element={<Committee />} />
        </Route>

        <Route path={ROUTE_COMPANY}>
          <Route index element={<CompanyCreate />} />
          <Route path=':companyId'>
            <Route index element={<Navigate to='commonInfo' replace />} />
            <Route path=':tab' element={<CompanyView />}>
              <Route path=':segment' element={<CompanyView />} />
            </Route>
            <Route path='edit' element={<CompanyEdit />} />
          </Route>
        </Route>

        <Route path={ROUTE_REVIEWS}>
          <Route index element={<ReviewsList />} />
          <Route path=':companyId/:reviewId'>
            <Route index element={<Navigate to='commonInfo' replace />} />
            <Route path=':tab' element={<ReviewView />} />
          </Route>
        </Route>

        <Route path={ROUTE_TENDERS}>
          <Route index element={<TendersList />} />
          <Route path=':tenderId'>
            <Route index element={<Navigate to='info' replace />} />
            <Route path=':tab' element={<TenderView />} />
            <Route path='edit' element={<TenderEdit />} />

            <Route path='proposals/winner'>
              <Route path=':tenderType'>
                <Route index element={<TenderWinners />} />
              </Route>
              <Route path=':lotId/:tenderType'>
                <Route index element={<TenderWinners />} />
              </Route>
            </Route>

            <Route
              path='lots/:lotId/proposals/analysis'
              element={<TenderLotProposalsAnalysis />}
            />
          </Route>
        </Route>

        <Route path={ROUTE_CONTRACT}>
          <Route index element={<ContractsList />} />
          <Route path=':contractId'>
            <Route index element={<Navigate to='info' replace />} />
            <Route path=':tab' element={<ContractView />} />
          </Route>
        </Route>

        <Route path={ROUTE_DOCUMENTATION}>
          <Route index element={<Navigate to='agreement' replace />} />
          <Route path=':tab' element={<DocumentationRoute />} />
        </Route>

        <Route path={ROUTE_SUPPORT} element={<SupportRoute />} />

        <Route path={ROUTE_DOWNLOAD} element={<DownloadRoute />} />

        <Route path={ROUTE_FORBIDDEN} element={<ForbiddenRoute />} />

        <Route path='*' element={<RedirectByRoles />} />
      </SentryRoutes>
    </Suspense>
  );
};

export default AppRoutes;
