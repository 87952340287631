import { ProtoObject } from 'types/common/common';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const isEmpty = <T>(obj: T): boolean => !obj || !Object.keys(obj).length;

const entries = <T extends object>(obj: T): Entries<T> => {
  return Object.entries(obj) as any;
};

export const getOptions = <T extends NonNullable<unknown>>(obj: T) =>
  entries(obj).map(([value, label]) => ({ label, value }));

export const splitValues = <T>(obj: T, name1: keyof T, name2: keyof T) => {
  const value = obj[name1];

  if (!value) {
    obj[name2] = value;

    return;
  }

  if (Array.isArray(value)) {
    const [value1, value2] = value;

    obj[name1] = value1;
    obj[name2] = value2;
  } else {
    obj[name2] = value;
  }
};

export const isBoolean = (value: any): boolean => typeof value === 'boolean';

export const convertObjectFormData = (value: any) => {
  return typeof value === 'object' && value.constructor !== File
    ? new Blob([JSON.stringify(value)])
    : value;
};

// Эта реализация нужна для обхода ошибки типизации при обращении к ключу, который находится по строке
export function hasOwnProperty<X extends ProtoObject, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return Object.hasOwn(obj, prop);
}

export const getPropertyValue = <X extends ProtoObject, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): string => (hasOwnProperty(obj, prop) ? String(obj[prop]) : '');

interface IObjectListToObjectKeys<T, U> {
  list: T[];
  defaultValue?: U;
  predicate?: (item: T) => U;
  prefix?: string;
}

// использовать, когда есть форма с вложенными полями и для них нужно задать начальные значения
/** @param list - [{ id: number, ...rest }] / любой список объектов, для каждого из которых определен id */
/** @param defaultValue - U = string / значение по умолчанию, которое можно будет получить по клчюу '_id' */
/** @param predicate? - (item) => U / функция, которая определяет дефолтное возвращаемое значение */
/** Удобно применять, когда для значения по умолчанию нужно проверить некоторое условие */
export const objectListToObjectKeys = <T extends { id: number }, U>({
  list,
  defaultValue,
  predicate,
  prefix = '',
}: IObjectListToObjectKeys<T, U>) => {
  return list.reduce(
    (acc, curr) => ({
      ...acc,
      [`${prefix}_${curr.id}`]: (predicate && predicate(curr)) || defaultValue,
    }),
    {},
  );
};

export const generateArrayOfObjects = (n: number) =>
  Array.from({ length: n }).map(() => ({}));
