/** Не хранить вместе с вызовом биндов inversify! https://github.com/inversify/InversifyJS/issues/1455 */
/** Список DI */
export enum TenderMSDI {
  TenderApi = 'TenderApi',
  TenderQueryLayer = 'TenderQueryLayer',
  TenderQueryKeysService = 'TenderQueryKeysService',

  TendersListApi = 'TendersApi',
  TendersListApiServiceQueryLayer = 'TendersQueryLayer',
  TendersListQueryKeysService = 'TendersQueryKeysService',

  LotsApiService = 'LotsApiService',
  LotsApiServiceQueryLayer = 'LotsApiServiceQueryLayer',
  LotsApiServiceQueryKeys = 'LotsApiServiceQueryKeys',

  ProposalsApiService = 'ProposalsApiService',
  ProposalsApiServiceQueryLayer = 'ProposalsApiServiceQueryLayer',
  ProposalsApiServiceQueryKeys = 'ProposalsApiServiceQueryKeys',

  CompetenciesApiService = 'CompetenciesApiService',
  CompetenciesTabControllerFactory = 'CompetenciesTabControllerFactory',
  CompetenciesAlertServiceFactory = 'CompetenciesAlertServiceFactory',

  PaymentTermStageApiService = 'PaymentTermStageApiService',
  PaymentTermStageApiServiceQueryLayer = 'PaymentTermStageApiServiceQueryLayer',
  PaymentTermStageApiServiceQueryKeys = 'PaymentTermStageApiServiceQueryKeys',

  CriteriaApiService = 'CriteriaApiService',
  CriteriaApiServiceQueryLayer = 'CriteriaApiServiceQueryLayer',
  CriteriaApiServiceQueryKeys = 'CriteriaApiServiceQueryKeys',

  PTOSApiService = 'PTOSApiService',
  PTOSApiServiceQueryLayer = 'PTOSApiServiceQueryLayer',
  PTOSApiServiceQueryKeys = 'PTOSApiServiceQueryKeys',

  TenderModel = 'TenderModel',
  TenderCommitService = 'TenderCommitService',
  TenderController = 'TenderController',
  TenderEditController = 'TenderEditController',
  TenderEditModel = 'TenderEditModel',
  TenderDataFrontendConverter = 'TenderDataFrontendConverter',

  LotDataSourceFactory = 'LotDataSourceFactory',
  ProposalDataSourceFactory = 'ProposalDataSourceFactory',
  LotProposalDataSourceFactory = 'LotProposalDataSourceFactory',

  ProposalModelExtendedFactory = 'ProposalModelExtendedFactory',
  LotModelExtendedFactory = 'LotModelExtendedFactory',

  TenderModalParticipantsModelConstructor = 'TenderModalParticipantsModelConstructor',
  TenderModalParticipantsFactory = 'TenderModalParticipantsFactory',

  LotModelConstructor = 'LotModelConstructor',
  LotModelFactory = 'LotModelFactory',

  LotControllerConstructor = 'LotControllerConstructor',
  LotControllerFactory = 'LotControllerFactory',

  LotListControllerConstructor = 'LotListControllerConstructor',
  LotListControllerFactory = 'LotListControllerFactory',

  LotPbeCAMControllerConstructor = 'LotPbeCAMControllerConstructor',
  LotPbeWASControllerConstructor = 'LotPbeWASControllerConstructor',
  LotPbeControllerFactory = 'LotPbeControllerFactory',

  LotPbeUtilityFactory = 'LotPbeUtilityFactory',

  LotPbeFieldValidatorFactory = 'LotPbeFieldValidatorFactory',
  LotPbeValidatorFactory = 'LotPbeValidatorFactory',

  ProposalModelFactory = 'ProposalModelFactory',
  LotProposalsAnalysisController = 'LotProposalsAnalysisController',

  ServerResponseExtractor = 'ServerResponseExtractor',
  ServerResponseLotExtractor = 'ServerResponseLotExtractor',

  TenderEditValidators = 'TenderEditValidators',
  ValidationFactory = 'ValidationFactory',

  TenderPanelsFieldsManager = 'TenderPanelsFieldsManager',
}
