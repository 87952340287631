import { TToggleStore } from 'store/common/toggle/types';

type TWaffleItem = {
  is_active: boolean;
  last_modified: string;
};

export enum EWaffleSwitches {
  csi = 'csi',
  rating = 'rating',
  rebiddingDrawer = 'rebiddingDrawer',
  ym = 'ym',
  denyAnonymous = 'deny_anonymous',
  lotProposalsAnalysis = 'lotProposalsAnalysis',
  hideChat = 'hideChat',
  hideRegistrationButton = 'hideRegistrationButton',
  hideTelegramAlert = 'hideTelegramAlert',
  allPrices = 'allPrices',
  approvers = 'approvers',
  hideContract = 'hideContract',
  additionalCompanyFields = 'additionalCompanyFields',

  notifications = 'notifications',

  newContractFilter = 'newContractFilter',
  newTenderFilter = 'newTenderFilter',
  newAccreditationTab = 'newAccreditationTab',

  newYearTheme = 'newYearTheme',

  tenderCommitee = 'tenderCommitee',
}

export type TWaffle = {
  flags: Record<string, TWaffleItem>;
  switches: Record<EWaffleSwitches, TWaffleItem>;
  samples: Record<string, TWaffleItem>;
};

export const featuresMap: Record<EWaffleSwitches, keyof TToggleStore> = {
  csi: 'csi',
  rating: 'rating',
  rebiddingDrawer: 'rebiddingDrawer',
  ym: 'ym',
  deny_anonymous: 'denyAnonymous',
  lotProposalsAnalysis: 'lotProposalsAnalysis',
  hideChat: 'hideChat',
  hideRegistrationButton: 'hideRegistrationButton',
  hideTelegramAlert: 'hideTelegramAlert',
  allPrices: 'allPrices',
  approvers: 'approvers',
  hideContract: 'hideContract',
  additionalCompanyFields: 'additionalCompanyFields',
  newTenderFilter: 'newTenderFilter',
  newContractFilter: 'newContractFilter',
  newAccreditationTab: 'newAccreditationTab',
  newYearTheme: 'newYearTheme',
  notifications: 'notifications',
  tenderCommitee: 'tenderCommitee',
};
