import { SamoletAuthProvider } from 'samolet-oauth2';
import { IAuthProvider } from '../type';

export class SamoletProvider extends SamoletAuthProvider implements IAuthProvider {
  async getPromisifiedToken(): Promise<string> {
    await this.init();
    const token = this.getToken();

    if (token) return Promise.resolve(token);

    return Promise.reject('JWT token is invalid');
  }

  updateToken(cb?: () => Promise<void>): void {
    cb?.();
  }
}
