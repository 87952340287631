import styled from 'styled-components';
import { Spinner as UiSpin } from '@10d/tend-ui-primitives';
import { tendUiColors } from 'constants/colors';

export const FullScreenSpinner = styled(UiSpin).attrs({
  color: tendUiColors.blue600,
  size: 'medium',
})`
  &.tend-ui-spinner {
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;
