import axios, { AxiosRequestConfig, CancelToken, CanceledError } from 'axios';
import qs from 'qs';
import { captureException, withScope } from '@sentry/react';
import { Toast, requestErrorToast } from 'components/Toast';

import { AxiosOptions, Method, VALID_STATUSES } from 'api/types';
import { ESentryTag } from 'utils/sentry';

const DEFAULT_CONTENT_TYPE = 'application/json';

const getCookie = (name: string) => {
  let cookieValue = null;

  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }

  return cookieValue;
};
export const headers = {
  Accept: 'application/json',
  'Content-Type': DEFAULT_CONTENT_TYPE,
  'X-CSRFToken': getCookie('csrftoken') || '',
};

const config: AxiosRequestConfig = {
  headers,
  // преобразует параметры из вида project[]=1&project[]=2 в project=1&project=2
  paramsSerializer: params => qs.stringify(params, { indices: false }),
};

export const instance = axios.create(config);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const codeStatus = error?.response?.status;
    const isCancelledRequest = error instanceof CanceledError;
    const isValidError = VALID_STATUSES.includes(codeStatus);

    if (!isCancelledRequest || !isValidError) {
      withScope(scope => {
        scope.setLevel('warning');
        scope.setTag(ESentryTag.WhereWasCaught, 'api/request');
        scope.setExtra(
          'message',
          `Captured server-side error code ${codeStatus || 'EMPTY CODE'}`,
        );
        captureException(error);
      });
    }

    return Promise.reject(error);
  },
);

const request = <T extends AxiosOptions>(
  method: Method,
  url: string,
  options?: T,
  hideAlert?: boolean,
): Promise<any> => {
  // ручная проверка на форму, т.к. axios в версиях 1.х перестал автоматически
  // менять заголовок Content-Type на multipart/form-data,
  // если для инстанса уже прописан Content-Type
  // https://github.com/axios/axios/issues/5556
  const isFormData = options?.data instanceof FormData;
  const newOptions = {
    ...options,
    headers: {
      ...options?.headers,
      'Content-Type': isFormData
        ? 'multipart/form-data'
        : options?.headers?.['Content-Type'] || DEFAULT_CONTENT_TYPE,
    },
  };

  return instance({
    method,
    url: url,
    ...newOptions,
  })
    .then(({ data }) => data || {})
    .catch(err => {
      const codeStatus = err?.response?.status;

      if (codeStatus) {
        if (codeStatus === 403) {
          // пустой return нужен для поглощения 403 ошибок неавторизованного пользователя
          if (hideAlert === undefined) return;

          if (!hideAlert) requestErrorToast(err);
        } else if (codeStatus === 413) {
          Toast.warning({ message: 'Загрузите файл меньшего размера' });
        } else {
          console.error(err.response);

          if (!hideAlert) requestErrorToast(err);
        }
      }
      throw err;
    });
};

export const get = <T>(
  url: string,
  params?: T,
  hideAlert?: boolean,
  options?: Partial<AxiosOptions>,
) => request<{ params?: T }>('get', url, { params, ...options }, hideAlert);
export const post = <T>(
  url: string,
  data?: T,
  hideAlert?: boolean,
  options?: Partial<AxiosOptions>,
) => request<{ data?: T } & typeof options>('post', url, { ...options, data }, hideAlert);
export const put = <T>(
  url: string,
  data?: T,
  hideAlert?: boolean,
  options?: Partial<AxiosOptions>,
) => request<{ data?: T } & typeof options>('put', url, { ...options, data }, hideAlert);
export const patch = <T>(
  url: string,
  data?: T,
  hideAlert?: boolean,
  options?: Partial<AxiosOptions>,
) =>
  request<{ data?: T } & typeof options>('patch', url, { ...options, data }, hideAlert);
export const del = <T>(url: string, data?: T) =>
  request<{ data?: T }>('delete', url, { data });

export const revocableGet = <T extends AxiosOptions['params']>(
  url: string,
  params?: T,
  token?: CancelToken,
  hideAlert?: boolean,
) => request('get', url, { params, cancelToken: token }, hideAlert);
