import { TAppVersion } from 'api/types';
import { getStandFromEnv } from 'utils/stand';

export const EDIT = 'edit';
export const VERSIONS = 'versions';

// COMPANY
const COMPANY = 'company';
export const ROUTE_COMPANY = `/${COMPANY}`;

// ACCREDITATION
const ACCREDITATION = 'accreditation';
export const ROUTE_ACCREDITATION = `/${ACCREDITATION}`;
export const ROUTE_ACCREDITATION_VIEW = `${ROUTE_ACCREDITATION}/:companyId`;

// COMMITTEE
const COMMITTEE = 'committees';
export const ROUTE_COMMITTEES = `/${COMMITTEE}`;

// CONTRACTOR
export const CONTRACTOR = 'contractor';
export const ROUTE_CONTRACTOR = `/${CONTRACTOR}`;
export const ROUTE_CONTRACTOR_VIEW = `${ROUTE_CONTRACTOR}/:companyId`;

//REVIEW
export const REVIEW = 'review';
export const ROUTE_REVIEWS = `/${REVIEW}`;

// TENDER
export const TENDERS = 'tenders';
export const ROUTE_TENDERS = `/${TENDERS}`;
export const ROUTE_TENDERS_VIEW = `${ROUTE_TENDERS}/:tenderId`;
export const ROUTE_TENDERS_VIEW_TAB = `${ROUTE_TENDERS}/:tenderId/:tab`;

// CONTRACT
const CONTRACT = 'contract';
export const ROUTE_CONTRACT = `/${CONTRACT}`;

// AUTH
export const LOGIN = '/accounts/login/samolet/';

// DOCUMENTATION
const DOCUMENTATION = 'documentation';
export const ROUTE_DOCUMENTATION = `/${DOCUMENTATION}`;

// SUPPORT
const SUPPORT = 'support';
export const ROUTE_SUPPORT = `/${SUPPORT}`;

// DOWNLOAD FILES
export const ROUTE_DOWNLOAD = '/download';

// ERRORS
export const ROUTE_FORBIDDEN = '/403';

export enum DOCUMENTATION_TABS_ROUTES {
  COOKIES = 'cookies',
  INSTRUCTIONS = 'instructions',
  PERSONAL_DATA_AGREEMENT = 'agreement',
  PRIVATE_DATA_POLICY = 'policy',
  PLATFORM_REGULATION = 'platformRegulation',
  SOFTWARE_USAGE = 'softwareUsage',
}

export const PRO_SAMOLET_LINK = 'https://pro.samoletgroup.ru/';

const SCEC_KS2_ACTS_PROD_URL = 'https://scec.samoletgroup.ru/ks2-acts/';
const SCEC_KS2_ACTS_STAGE_URL = 'https://scec-stage.samoletgroup.ru/ks2-acts/';
export const SCEC_KS2_ACTS_URL =
  getStandFromEnv() === TAppVersion.PROD
    ? SCEC_KS2_ACTS_PROD_URL
    : SCEC_KS2_ACTS_STAGE_URL;
