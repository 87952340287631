import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { queryKey } from 'api/types';
import { EWaffleSwitches, TWaffle, apiWaffle, featuresMap } from 'api/api/waffle';
import { TToggleStore } from 'store/common/toggle/types';
import { toggleActions } from 'store/common/toggle';

interface IUseWaffle {
  isLoading: boolean;
  waffle: TWaffle;
  getWaffle: () => Promise<QueryObserverResult<TWaffle, unknown>>;
}

export const useWaffle = (): IUseWaffle => {
  const {
    data: waffle = {} as TWaffle,
    refetch: getWaffle,
    isLoading,
  } = useQuery([queryKey.waffle], () => apiWaffle());

  return {
    isLoading,
    waffle,
    getWaffle,
  };
};

export const useDispatchWaffles = () => {
  const dispatch = useDispatch();
  const { waffle, isLoading } = useWaffle();
  const [isDispatched, setIsDispatched] = useState(false);

  const passToStore = useCallback(() => {
    const result: TToggleStore = {} as TToggleStore;

    for (const sw in waffle.switches) {
      result[featuresMap[sw as EWaffleSwitches]] = {
        isEnabled: waffle.switches[sw as EWaffleSwitches].is_active || false,
      };
    }
    dispatch(toggleActions.add(result));
  }, [dispatch, waffle]);

  useEffect(() => {
    if (!isLoading) {
      passToStore();
      setIsDispatched(true);
    }
  }, [isLoading, passToStore]);

  return {
    isDispatched,
  };
};
