import React from 'react';
import { NavigationItem } from '@10d/tend-ui-header/core/types';
import { Link } from 'react-router-dom';
import {
  AsideMenuItemsKeys,
  AsideMenuItemsRules,
  IAsideMenuCreator,
  asideMenuItemsPermissions,
  asideMenuItemsRules,
  asideMenuItemsValues,
} from './types';

const createAsideMenuItem = (
  type: AsideMenuItemsKeys,
  onClick: (key: AsideMenuItemsKeys) => void,
): NavigationItem => ({
  label: <Link to={`/${type.toLowerCase()}`}>{asideMenuItemsValues[type]}</Link>,
  key: type,
  onClick: () => onClick(type),
});

const asideMenuFilter = (
  {
    employee,
    notEmployee,
    permissions,
    isAnonymous,
    hideContract,
    isCommitteeEnabled,
  }: IAsideMenuCreator,
  items: NavigationItem[],
): NavigationItem[] =>
  items.filter(item => {
    const itemRules = asideMenuItemsRules[item.key as AsideMenuItemsKeys];
    const itemPermissions = asideMenuItemsPermissions[item.key as AsideMenuItemsKeys];

    if (item.key === AsideMenuItemsKeys.COMMITTEES && !isCommitteeEnabled) return false;

    if (
      itemPermissions &&
      !itemPermissions?.some(permission => permissions?.includes(permission))
    )
      return false;

    if (hideContract && item.key === AsideMenuItemsKeys.CONTRACT) return false;

    if (itemRules.includes(AsideMenuItemsRules.All)) return true;

    if (employee && itemRules.includes(AsideMenuItemsRules.Employee)) return true;

    if (!isAnonymous && itemRules.includes(AsideMenuItemsRules.NotDemo)) return true;

    if (notEmployee && itemRules.includes(AsideMenuItemsRules.NotEmployee)) return true;

    return false;
  });

export const asideMenuItems = (props: IAsideMenuCreator): NavigationItem[] =>
  asideMenuFilter(
    props,
    Object.values(AsideMenuItemsKeys).map(key => createAsideMenuItem(key, props.onClick)),
  );
