import { getRealm } from 'samolet-oauth2';
import { authType } from 'api/auth';
import { IAuthProvider } from './type';
import { KeycloakProvider, SamoletProvider } from './providers';
import { Envs } from './common/enums';

export class CurrentProvider {
  constructor(private readonly allowAnonymous: boolean) {}

  public getCurrentProvider(): IAuthProvider {
    switch (authType) {
      case Envs.SAAS:
        return new KeycloakProvider({
          clientId: process.env.KEYCLOAK_OAUTH2_CLIENT_ID ?? '',
          allowAnonymous: this.allowAnonymous,
          realm: getRealm(),
          url: process.env.KEYCLOAK_URL,
        });
      case Envs.SAMOLET:
      default:
        return new SamoletProvider({
          clientId: process.env.SOCIAL_AUTH_SAMOLET_KEY ?? '',
          allowAnonymous: true,
        });
    }
  }
}
