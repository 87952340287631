import { injectable } from 'inversify';
import { post } from 'api';
import { API_VERSION } from 'api/routes';
import { AxiosOptions, IAWSApiUploadResponse } from 'api/types';
import { IAWSAPiService } from './types';

@injectable()
class AWSIApiService implements IAWSAPiService {
  upload(
    data: FormData,
    hideAlert = false,
    options?: Partial<AxiosOptions>,
  ): Promise<IAWSApiUploadResponse> {
    return post(`${API_VERSION.V1}/aws`, data, hideAlert, options);
  }
}

export default AWSIApiService;
