import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { FIVE_MINUTES } from '@webcommon/constants';
import { TVersion, queryKey } from 'api/types';
import { getAppVersion } from 'api/api';

interface IUseCheckVersion {
  appVersion: TVersion['version'];
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
}

export const useCheckVersion = (): IUseCheckVersion => {
  const {
    data = '',
    isFetching,
    isFetched,
    isLoading: isCheckLoading,
  } = useQuery([queryKey.appVersion], () => getAppVersion(), {
    select: response => response.version,
    refetchInterval: FIVE_MINUTES,
  });

  const isLoading = useMemo(
    () => isCheckLoading || isFetching,
    [isCheckLoading, isFetching],
  );

  return { appVersion: data, isLoading, isFetching, isFetched };
};
