import React from 'react';
import { AxiosError } from 'axios';
import { Toast } from '@10d/tend-ui/primitives/Toast';
import { TAixosErrorResponse } from 'api/types';
import { Text } from '../Typography/Text';
import { Strong } from '../Typography';
import type { ToastConfig } from './types';

const getContentFromError = (err: AxiosError<TAixosErrorResponse>) => {
  const codeStatus = err?.response?.status;

  if (typeof err?.response?.data !== 'object') {
    return (
      <>
        <Text>
          <Strong>Код ошибки</Strong>: {codeStatus}
        </Text>
        {err?.response?.data}
      </>
    );
  }

  if (Array.isArray(err?.response?.data?.error)) {
    return err?.response?.data.error.map((error: string) => (
      <div key={error}>{error}</div>
    ));
  }

  if (typeof err?.response?.data === 'object' && err?.response?.data !== null) {
    return Object.keys(err.response?.data).map((key, id) => {
      if (typeof err?.response?.data[key] === 'object') throw new Error();

      return (
        <div key={`${key}${id}`}>
          <Text strong>{key}</Text>: {err?.response?.data[key] as string}
        </div>
      );
    });
  }
};

export const requestErrorToast = (error: AxiosError<TAixosErrorResponse>) => {
  let content: any;
  let colon = ':';
  try {
    content = getContentFromError(error);
  } catch {
    colon = '';
  }

  const config: ToastConfig = {
    message: `Ошибка при обращении к серверу ${colon}`,
    description: content,
  };

  return Toast.error(config);
};

export const inDevToast = () => Toast.warning({ message: 'Находится в разработке' });

export const errorToast = (msg: string | React.ReactNode | null = null) => {
  const config: ToastConfig = {
    message: 'Ошибка при обращении к серверу',
    description: msg,
  };

  Toast.error(config);
};
