import { inject, injectable } from 'inversify';
import { GenericObject } from '@10d/tend-ui/types';
import type {
  IListMapModel,
  IListMapModelSettings,
  TListMapModelConstructor,
} from 'models/List/types';
import { EGlobalDIKeys } from 'core/di/keys';
import { IListMapFactoryService } from './types';

@injectable()
class ListMapFactoryService implements IListMapFactoryService {
  constructor(
    @inject(EGlobalDIKeys.ListMapModelConstructor)
    private readonly _listConstructor: TListMapModelConstructor,
  ) {}

  create<T extends GenericObject, K extends keyof T = keyof T>(
    settings: IListMapModelSettings<T>,
  ): IListMapModel<T, K> {
    return new this._listConstructor(settings);
  }
}

export default ListMapFactoryService;
