import { injectable } from 'inversify';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  transaction,
} from 'mobx';
import { GenericObject } from '@10d/tend-ui/types/GenericObject';
import type { IListMapModel, IListMapModelSettings } from './types';

@injectable()
class ListMapModel<T extends GenericObject, K extends keyof T = keyof T>
  implements IListMapModel<T, K>
{
  private _byKey?: K;

  @observable
  private _order: T[K][];

  @observable
  public items: Record<T[K], T>;

  constructor(settings: IListMapModelSettings<T>) {
    this.items = {} as Record<T[K], T>;
    this._byKey = settings.byKey as K;
    this._initMap(settings.items);
    makeObservable(this);
  }

  @action
  addItems = (items: T[]) => {
    transaction(() => items?.forEach(this.addItem));
  };

  @action
  addOnce = (item: T) => {
    this.addItem(item);
  };

  @computed
  public get length() {
    return Object.keys(this.items).length;
  }

  @action
  public clear = (): void => {
    this.items = {} as IListMapModel<T, K>['items'];
    this._order = {} as typeof this._order;
  };

  public getItem = (key: T[K]): T => {
    return this.items[key];
  };

  @action
  public setItems = (items: T[]): void => {
    this._initMap(items);
  };

  @action
  public addItem = (item: T, index?: number): void => {
    const key = (
      this._byKey != null && this._byKey !== 0 ? item[this._byKey] : index
    ) as K;
    const newItems = { ...this.items, [key]: item };

    this.items = newItems;

    const newLength = Object.keys(newItems).length;
    const targetIndex = index == null ? newLength : index;
    this._order = [
      ...this._order.slice(0, targetIndex),
      key as T[K],
      ...this._order.slice(targetIndex, newLength),
    ];
  };

  @action
  public removeItemByKey = (key: T[K]): void => {
    delete this.items[key];
    this._order.splice(this._order.indexOf(key), 1);
  };

  @action
  public removeItem = (item: T) => {
    if (!this._byKey)
      throw new Error('ListMapModel needs to have "byKey" setting for this move');
    this.removeItemByKey(item[this._byKey]);
  };

  public toArray = (): T[] => {
    return this._order.map(key => this.items[key]);
  };

  private _initMap = (items: T[]) => {
    const newOrder = [] as typeof this._order;
    const newItems = {} as typeof this.items;
    items.forEach((item, index) => {
      const key = (
        this._byKey != null && this._byKey !== 0 ? item[this._byKey] : index
      ) as T[K];

      newItems[key] = item;
      newOrder.push(key);
    });

    runInAction(() => {
      this.items = newItems;
      this._order = newOrder;
    });
  };
}

export default ListMapModel;
