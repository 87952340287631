import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { JWTPayload, decodeJwt } from 'jose';
import { useSelector } from 'react-redux';
import { FullScreenSpinner } from 'components/Spinner';
import { authType } from 'api/auth';
import { instance } from 'api/request';
import { selectEnabled } from 'store/common/toggle';
import { IAuthProvider } from './type';
import { Envs } from './common/enums';
import { CurrentProvider } from './helpers';

interface IAuthContext {
  token: string | undefined;
  claim?: JWTPayload;
  provider: IAuthProvider;
}

const AuthData = React.createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [token, setToken] = useState<string | undefined>();
  const [isLoading, setLoading] = useState(true);
  const isAnonymousDisabled = useSelector(selectEnabled('denyAnonymous'));

  const currentProvider = useMemo<IAuthProvider>(() => {
    const provider = new CurrentProvider(!isAnonymousDisabled).getCurrentProvider();

    provider.setAuthInterceptor(instance);
    window.currentProvider = provider;

    return provider;
  }, [isAnonymousDisabled]);

  const claim = useMemo(() => (token ? decodeJwt(token as string) : undefined), [token]);

  const authorize = useCallback(async () => {
    try {
      await currentProvider.init();
      setToken(currentProvider.getToken());
      if (authType === Envs.SAAS) {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [currentProvider]);

  useEffect(() => {
    authorize();
  }, [authorize]);

  const value = useMemo(
    (): IAuthContext => ({
      token,
      claim: token ? claim : undefined,
      provider: currentProvider,
    }),
    [claim, currentProvider, token],
  );

  if (isLoading && authType === Envs.SAAS) return <FullScreenSpinner />;

  return <AuthData.Provider value={value}>{children}</AuthData.Provider>;
};

export const useAuth = () => useContext(AuthData);
